import logo from './logo.svg';
import './App.css';

function App() {
  return (


      <div className="header">
        <div className="actions-left">
          <div className="actions">
            <div className="action-menu">
              <div className="menu-popup">
                <ul>
                  <li><a href="">Настройки аккаунта</a></li>
                  <li>
                    <a href=""><b>Мои дети</b></a>
                  </li>
                  <li>
                    <a href=""><b>Мои инфопродукты</b></a>
                  </li>
                  <li><a href="">Все инфопродукты</a></li>
                  <li><a href="">Поиск специалиста</a></li>
                  <li><a href="">Служба заботы</a></li>
                  <li><a href="">О приложении</a></li>
                  <li><br/></li>
                  <li><a href="">Выйти из аккаунта</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="profile">
            <div className="profile-avatar">
              <img src="/assets/img/profile-avatar.png" alt="profile-avatar"/>
            </div>
            <div className="profile-title">
              <div className="profile-name">Анастасия</div>
              <div className="profile-status">2 года 4 месяца</div>
            </div>
          </div>
        </div>
        <div className="actions-right">
          <div className="actions">
            <a href="#" className="action-notification" data-popup="notifications">
              <span className="notification-new active"></span>
            </a>
            <a href="" className="action-profile"></a>
            <a href="" className="action-settings"></a>
          </div>
        </div>
      </div>



    //
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
